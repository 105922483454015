@font-face {
  font-family: BrandonGrotesque-Thin;
  src: url('./BrandonGrotesque-Thin.ttf');
}

.circle_animation_one {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 40px solid #6dc9e9;
  position: absolute;
  top: 10px;
  left: -50px;
  z-index: -1;
  animation: animate_one;
  animation-duration: 40s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.circle_animation_two {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 20px solid #fbdf53;
  position: absolute;
  top: 50px;
  left: 150px;
  z-index: -1;
  animation: animate_two;
  animation-duration: 26s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes animate_one {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animate_two {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
